import React from "react";

import { meta } from "../../content_option";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { HeaderDark, Headermain } from "../../header";
import { makeStyles } from "@material-ui/core";
import CardILegacy from "../../components/illumesCard/index";
import CardIEssential from "../../components/illumesCard/essential";
import AddoN from "../../components/illumesCard/addOn";
import Footer from "../../components/footer";
import FollowUs from "../../components/followUs";

export default function Illumes() {
  const classes = useStyles();
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Illumes | {meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Headermain />

      <div>
        <section className={classes.contMain}>
          <CardILegacy />
          <CardIEssential />
          <AddoN />
        </section>
      </div>
      <FollowUs />
      <Footer />
    </HelmetProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  contMain: {
    color: "#363737",
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "3rem",
    [theme.breakpoints.down("sm")]: {
      rowGap: "0",
    },
    '@media (max-width: 768px)': {  
      rowGap: "0", 
    },
  },
  heading: {
    fontSize: 28,
    letterSpacing: 3,
  },
}));
