import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import InvesmentsCardA from "./cardA";
import { useSelector } from "react-redux";

export default function InvestmentHead() {
  const classes = useStyles();
  const { allDoc, allDocLoading } = useSelector((state) => state.planSlicer);

  return (
    <section className={classes.contMain}>
      <div className={classes.heading}>INVESTMENTS</div>
      <div className={classes.content}>
        <p className={classes.paragraph}>
          Wedding photography is one of the most important investments of
          someone's life. Selecting the right photographer to document your
          special moments is challenging. We make this process comfortable for
          our clients by offering several creative consultations. We strive to
          make our client's experience as comfortable and memorable as possible.
        </p>
      </div>
      <div className={classes.content}>
        <p className={classes.paragraph}>
          A few other aspects that set iNavphoto apart from others. We dedicate
          a lot of effort into each wedding. We are just a text message away
          from our clients. We don't outsource our editing process. Every photo
          we edit receives special love and attention that reflects our unique
          style.
        </p>
      </div>
      {allDocLoading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.cards}>
          {allDoc && allDoc.slice(0, 3).map((item) => (
            <InvesmentsCardA key={item.id} item={item} />
          ))}
        </div>
      )}
    </section>
  );
}

// const useStyles = makeStyles((theme) => ({
//   contMain: {
//     padding:"5rem 10vw",
//     marginTop:"-11%"
//   },
//   heading: {
//     // padding: theme.spacing(12, 0),
//     paddingTop:"30px",
//     paddingBottom:"50px",
//     fontSize: '30px',
//     textAlign: "center",
//     fontFamily: 'Silk Serif ExtraLight, serif',
//     textTransform: 'none',
//     color: 'rgb(144, 144, 144)',
//     marginBottom: "-15%",
//     marginTop: "-22%",
//   },
//   content: {
//     marginBottom: theme.spacing(4),
//     marginTop:"30px"
//   },
//   paragraph: {
//     fontFamily: 'Quarto Light, serif',
//     textTransform: 'none',
//     fontSize: '18px',
//     color: 'rgb(144, 144, 144)',
//     maxWidth: "70vw",
//     margin: "0 auto",
//     textAlign: "center",
//     [theme.breakpoints.down("sm")]: {
//       fontSize: theme.typography.body2.fontSize,
//     },
//   },
//   cards: {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
//     gap: theme.spacing(7),
//     marginTop:"7rem",

//   },
//   loader: {
//     display: "flex",
//     justifyContent: "center",
//   },
// }));



const useStyles = makeStyles((theme) => ({
  contMain: {
    padding: "5rem 10vw",
    marginTop: "-11%",
    [theme.breakpoints.down("sm")]: {
      padding: "3rem 5vw", 
      marginTop: "-5%", 
    },
    [theme.breakpoints.up("md")]: {
      padding: "5rem 7vw", 
    },
    '@media (max-width: 375px)': {  // Custom media query for screens 320px to 375px
      marginTop: "-8%",  
    },
    '@media (min-width: 376px) and (max-width: 425px)': {  // Custom media query for screens 375px to 425px
      marginTop: "-7%", 
    },
    '@media (min-width: 426px) and (max-width: 768px)': {  // Custom media query for screens 426px to 768px
      marginTop: "-14%",  
    },
  },
  heading: {
    padding: theme.spacing(12, 0),
    fontSize: '30px',
    textAlign: "center",
    fontFamily: 'Silk Serif ExtraLight, serif',
    textTransform: 'none',
    color: 'rgb(144, 144, 144)',
    marginBottom: "-5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: '24px', 
      marginBottom: "0", 
    },
    [theme.breakpoints.up("md")]: {
      fontSize: '30px', 
    },
    '@media (max-width: 375px)': {  // Custom media query for screens 320px to 375px
      fontSize: '20px',  
      marginBottom: "-25%",
      marginTop:"-30%"  
    },
    '@media (min-width: 376px) and (max-width: 425px)': {  // Custom media query for screens 375px to 425px
      fontSize: '22px',  
      marginBottom: "-21%",
      marginTop:"-22%"  
    },
    '@media (min-width: 426px) and (max-width: 768px)': {  // Custom media query for screens 426px to 768px
      fontSize: '26px',  
      marginBottom: "-5%",  
    },
  },
  content: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2), 
    },
  },
  paragraph: {
    fontFamily: 'Quarto Light, serif',
    textTransform: 'none',
    fontSize: '18px',
    color: 'rgb(144, 144, 144)',
    maxWidth: "70vw",
    margin: "0 auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body2.fontSize, 
      maxWidth: "90vw", 
    },
    [theme.breakpoints.up("md")]: {
      fontSize: '18px', 
      maxWidth: "70vw", 
    },
    '@media (max-width: 375px)': {  // Custom media query for screens 320px to 375px
      fontSize: '16px',  
      maxWidth: "80vw", 
    },
    '@media (min-width: 376px) and (max-width: 425px)': {  // Custom media query for screens 375px to 425px
      fontSize: '17px',  
      maxWidth: "85vw", 
    },
    '@media (min-width: 426px) and (max-width: 768px)': {  // Custom media query for screens 426px to 768px
      fontSize: '18px',  
      maxWidth: "75vw", 
    },
  },
  cards: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    // gap: theme.spacing(7),
    // marginTop: "7rem",
    [theme.breakpoints.down("sm")]: {
      // marginTop: "4rem", 
      // gap: theme.spacing(4), 
    },
    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(7), 
    },
    '@media (max-width: 375px)': {  // Custom media query for screens 320px to 375px
      marginTop: "5rem", 
    },
    '@media (min-width: 376px) and (max-width: 425px)': {  // Custom media query for screens 375px to 425px
      // marginTop: "5rem", 
      // gap: theme.spacing(4),  
    },
    '@media (min-width: 426px) and (max-width: 768px)': {  // Custom media query for screens 426px to 768px
      // marginTop: "6rem",  
      gap: theme.spacing(5),  
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem", 
    },
    '@media (max-width: 375px)': {  // Custom media query for screens 320px to 375px
      marginTop: "1rem",  
    },
    '@media (min-width: 376px) and (max-width: 425px)': {  // Custom media query for screens 375px to 425px
      marginTop: "1.5rem",  
    },
    '@media (min-width: 426px) and (max-width: 768px)': {  // Custom media query for screens 426px to 768px
      marginTop: "2rem",  
    },
  },
}));
